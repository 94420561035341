import { FileUploader } from "@/components/FileUpload";
import { GoogleMapsView } from "@/components/GoogleMapsView";
import { MultiSelect as MultipleSelector } from "@/components/MultiSelect";
import { Option } from "@/components/MultiSelect";
import { SearchAddress } from "@/components/SearchAddress";
import { DatePicker } from "@/components/ui/datePicker";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { buyerTypes } from "@/constants/general";
import { useCreateResourceCategory } from "@/hooks/builder/useCreateResourceCategory";
import { useAuth } from "@/hooks/login/useAuth";
import { useDrawingManager } from "@/hooks/useDrawingManager";
import { BuilderResourceFormSchemaType } from "@/schemas/builders/builderResourceFormSchema";
import { CommunitySchemaType } from "@/schemas/communitySchema";
import { PhaseType } from "@/schemas/phaseSchema";
import { ProductTypeType } from "@/schemas/productTypeSchema";
import { Resource } from "@/schemas/resourceSchema";
import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
interface ResourceFormProps {
  folders: Resource[] | undefined;
  communities: CommunitySchemaType[] | undefined;
  phases: PhaseType[] | undefined;
  productTypes: ProductTypeType[] | undefined;
}

interface PolygonOverlayCompleteEvent
  extends google.maps.drawing.OverlayCompleteEvent {
  type: google.maps.drawing.OverlayType.POLYGON;
  overlay: google.maps.Polygon;
}

export const ResourceForm = ({
  folders,
  communities,
  phases,
  productTypes,
}: ResourceFormProps) => {
  const { t } = useTranslation("resource");

  const drawingManager = useDrawingManager();
  const [latEndLong, setLatEndLog] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);

  const { control } = useFormContext<BuilderResourceFormSchemaType>();
  const { mutateAsync: createResourceCategoryAsync } =
    useCreateResourceCategory();
  const { user } = useAuth();
  const formattedPhases = phases?.map((phase) => {
    return { label: phase.phase, value: phase.id.toString() };
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "locations",
  });
  const formattedProductTypes = productTypes?.map((productType) => {
    return {
      label: productType.product_type,
      value: productType.id.toString(),
    };
  });

  const formattedCommunityOptions = communities?.map((community) => {
    return {
      label: community.display_line,
      value: community.id.toString(),
    };
  });

  const formattedFolders = folders?.map((folder) => {
    return {
      label: folder.category_name,
      value: folder.id.toString(),
    };
  });

  const selectedCommunity = useWatch({
    control,
    name: "project_community_ids",
  });

  useEffect(() => {
    if (!drawingManager) return;

    const overlayCompleteListener = google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      (event: google.maps.drawing.OverlayCompleteEvent) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const polygonEvent = event as PolygonOverlayCompleteEvent;
          const path = polygonEvent.overlay.getPath();
          const coordinates = [];

          for (let i = 0; i < path.getLength(); i++) {
            const latLng = path.getAt(i);
            coordinates.push({
              lat: latLng.lat(),
              lng: latLng.lng(),
            });
          }

          append({
            service_area_polygon: coordinates,
          });

          drawingManager.setDrawingMode(null);
        }
      }
    );

    return () => {
      google.maps.event.removeListener(overlayCompleteListener);
    };
  }, [drawingManager]);

  return (
    <div className="grid grid-cols-2 gap-5 md:grid-cols-3 md:gap-5  w-full">
      <FormField
        control={control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("Title")}</FormLabel>
            <FormControl>
              <Input placeholder={t("Title")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="expiry_date"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("ExpiryDate")}</FormLabel>
            <FormControl>
              <DatePicker
                date={field.value}
                setDate={field.onChange}
                className="w-full"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="resource_category_id"
        render={({ field }) => {
          const selectedCategory =
            formattedFolders?.find(
              (folder) => Number(folder.value) === field.value
            ) ?? null;

          return (
            <FormItem>
              <FormLabel>{t("Folder")}</FormLabel>
              <FormControl>
                <MultipleSelector
                  options={formattedFolders ?? []}
                  onChange={(newValue) => {
                    // Update only the selected option value
                    const selectedValue = newValue[0]
                      ? Number(newValue[0].value)
                      : null;
                    field.onChange(selectedValue);
                  }}
                  value={selectedCategory ? [selectedCategory] : []}
                  onCreate={async (newValue) => {
                    const newResource = await createResourceCategoryAsync({
                      builder_account_id: user?.builder_accounts[0]?.id,
                      category_name: newValue.label,
                    });

                    const sortByResourceId = newResource.sort(
                      (a, b) => a.id - b.id
                    );
                    const lastElement =
                      sortByResourceId[sortByResourceId.length - 1];
                    return {
                      value: lastElement.id.toString(),
                      label: lastElement.category_name,
                    };
                  }}
                  maxSelected={1}
                  creatable
                  triggerSearchOnFocus
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />

      <hr className="col-span-2 sm:col-span-4 flex-col w-full" />

      <FormField
        control={control}
        name="project_community_ids"
        render={({ field, fieldState }) => {
          const c =
            selectedCommunity?.reduce((accumulator: Option[], communityId) => {
              const community = formattedCommunityOptions?.find(
                (option) => Number(option.value) === communityId
              );
              if (community) {
                accumulator.push({
                  value: community.value,
                  label: community.label,
                });
              }
              return accumulator;
            }, []) ?? [];

          return (
            <FormItem className="col-span-full">
              <FormLabel>{t("Communities")}</FormLabel>
              <FormControl>
                <MultipleSelector
                  options={formattedCommunityOptions ?? []}
                  value={c ?? []}
                  onChange={(newValue) => {
                    field.onChange(
                      newValue.map((option) => Number(option.value))
                    );
                  }}
                />
              </FormControl>
              {fieldState.error && (
                <FormMessage>{fieldState.error.message}</FormMessage>
              )}
            </FormItem>
          );
        }}
      />

      <FormField
        control={control}
        name="phase_ids"
        render={({ field }) => {
          const c =
            field.value?.reduce((accumulator: Option[], phaseId) => {
              const phase = formattedPhases?.find(
                (option) => Number(option.value) === phaseId
              );
              if (phase) {
                accumulator.push({ value: phase.value, label: phase.label });
              }
              return accumulator;
            }, []) ?? [];
          return (
            <FormItem className="col-span-full">
              <FormLabel>{t("Phases")}</FormLabel>
              <FormControl>
                <MultipleSelector
                  options={formattedPhases ?? []}
                  value={c ?? []}
                  onChange={(newValue) => {
                    field.onChange(
                      newValue.map((option) => Number(option.value))
                    );
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />

      <FormField
        control={control}
        name="product_type_ids"
        render={({ field }) => {
          const productTypes =
            field.value?.reduce((accumulator: Option[], phaseId) => {
              const phase = formattedProductTypes?.find(
                (option) => Number(option.value) === phaseId
              );
              if (phase) {
                accumulator.push({ value: phase.value, label: phase.label });
              }
              return accumulator;
            }, []) ?? [];
          return (
            <FormItem className="col-span-2 ">
              <FormLabel>{t("ProductTypes")}</FormLabel>
              <FormControl>
                <MultipleSelector
                  options={formattedProductTypes ?? []}
                  value={productTypes}
                  onChange={(newValue) => {
                    field.onChange(
                      newValue.map((option) => Number(option.value))
                    );
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />

      <FormField
        control={control}
        name="buyer_types"
        render={({ field }) => {
          const c =
            field.value?.reduce((accumulator: Option[], buyerTypeId) => {
              const buyerType = buyerTypes?.find(
                (option) => option.value === buyerTypeId
              );
              if (buyerType) {
                accumulator.push({
                  value: buyerType.value,
                  label: buyerType.key,
                });
              }
              return accumulator;
            }, []) ?? [];
          return (
            <FormItem className="col-span-1 xl:col-span-2">
              <FormLabel>{t("BuyerTypes")}</FormLabel>
              <FormControl>
                <MultipleSelector
                  options={buyerTypes.map((buyerType) => {
                    return {
                      label: buyerType.key, ///@TODO: fix this after launch
                      value: buyerType.value,
                    };
                  })}
                  value={c ?? []}
                  onChange={(newValue) => {
                    field.onChange(newValue.map((option) => option.value));
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />

      <FormField
        control={control}
        name="description"
        render={({ field }) => (
          <FormItem className="col-span-2 sm:col-span-4 flex-col w-full">
            <FormLabel>{t("Description")}</FormLabel>
            <FormControl>
              <Textarea
                placeholder={t("Description")}
                {...field}
                className="h-[135px] resize-none"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <hr className="col-span-2 sm:col-span-4 flex-col w-full" />
      <FormField
        control={control}
        name="locations"
        render={() => {
          return (
            <FormItem className="col-span-2 sm:col-span-4 flex-col w-full">
              <FormLabel>{t("Areas")}</FormLabel>
              <FormControl>
                <div className="flex gap-2 flex-col w-full ">
                  <SearchAddress
                    onSelectLocation={(location) => {
                      setLatEndLog({
                        lat: location?.lat ?? 0,
                        lng: location?.lng ?? 0,
                      });
                    }}
                  />
                  <GoogleMapsView
                    className="w-full h-96 rounded-md"
                    center={latEndLong}
                  >
                    <MapControl position={ControlPosition.TOP_CENTER} />
                  </GoogleMapsView>

                  <div>
                    {fields.map((field, index) => (
                      <div key={field.id}>
                        <h3>{field.name}</h3>
                        <p>{field.address}</p>
                        <button type="button" onClick={() => remove(index)}>
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </FormControl>
              <FormDescription className="text-red-60">
                {t("SelectAreaDescription")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          );
        }}
      />

      <hr className="col-span-2 sm:col-span-4 flex-col w-full" />

      <div className="col-span-1 sm:col-span-4 flex-col w-full">
        <FormField
          control={control}
          name="files"
          render={({ field }) => {
            return (
              <div className="col-span-2 sm:col-span-4 flex-col w-full">
                <FormItem className="w-full">
                  <FormLabel>{t("ResourceFile")}</FormLabel>
                  <FormControl>
                    <div className="space-y-6">
                      <FormItem className="w-full">
                        <FormControl>
                          <FileUploader
                            onValueChange={field.onChange}
                            maxFileCount={1}
                            maxSize={10 * 1024 * 1024} // 10 MB
                            accept={{
                              "application/pdf": [".pdf"],
                              "image/*": [".jpg", ".jpeg", ".png", ".webp"],
                            }}
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    </div>
                  </FormControl>
                </FormItem>
              </div>
            );
          }}
        />
        <hr className="col-span-2 sm:col-span-4 flex-col w-full my-6" />
        <FormField
          name="resource_url"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>{t("ResourceUrl")}</FormLabel>
              <FormControl>
                <Input {...field} placeholder={t("ResourceUrl")} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};
