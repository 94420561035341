import { Shell } from "@/components/Shell";
import { SupportCard } from "@/components/SupportCard";
import { TwoColumnLayout } from "@/components/TwoColumnLayout";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { PageHeader } from "@/components/ui/page-header";
import { useFetchActiveProjects } from "@/hooks/builder/useFetchActiveProjects";
import { useFetchProjectType } from "@/hooks/builder/useFetchProjectType";
import { useAuth } from "@/hooks/login/useAuth";
import { useCreateAccount } from "@/hooks/user/userCreateAccount";
import { useToast } from "@/hooks/utils/useToast";
import {
  CreatePropertySchema,
  CreatePropertyType,
} from "@/schemas/createPropertySchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PropertyForm } from "./PropertyPage/components/PropertyForm";

export const CreatePropertyPage = () => {
  const { t: gt } = useTranslation("general");
  const { t } = useTranslation("property");
  const { mutateAsync: createPropertyAsync, isPending } = useCreateAccount();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();

  const form = useForm<CreatePropertyType>({
    resolver: zodResolver(CreatePropertySchema),
    defaultValues: {
      user_properties: [
        {
          property: {
            is_manual_address: false,
          },
          user_property_status: [
            {
              phase_id: 1,
              status_id: 9,
            },
          ],
        },
      ],
    },
  });
  const { handleSubmit, watch } = form;
  const projectId = watch("user_properties.0.property.project_id");
  const { data: ActiveProjects } = useFetchActiveProjects({
    builderId: user.builder_accounts[0].id,
  });
  const { data: homeType } = useFetchProjectType({
    projectId: [projectId],
  });

  const handleCreateProperty = async (data: CreatePropertyType) => {
    await createPropertyAsync(data, {
      onSuccess: () => {
        toast({
          title: t("Toast.PropertyCreated"),
          description: t("Toast.PropertyCreatedDescription"),
          duration: 3000,
        });
      },
      onError: (error) => {
        toast({
          title: t("Toast.ErrorsTitle"),
          description: error.message,
        });
      },
    });
    navigate("/");
  };

  return (
    <Shell>
      <PageHeader
        title={gt("CreateCustomer")}
        subTitle={gt("CreateCustomerDescription")}
      />
      <TwoColumnLayout
        leftContent={
          <FormProvider {...form}>
            <Form {...form}>
              <form
                onSubmit={handleSubmit(handleCreateProperty)}
                className="bg-white p-10 rounded-lg flex flex-col  "
              >
                <PropertyForm
                  ActiveProjects={ActiveProjects}
                  homeType={homeType}
                />
                <div className="flex justify-end mt-6 space-x-4">
                  <Button
                    disabled={isPending}
                    variant="ghost"
                    onClick={() => navigate("/")}
                  >
                    {gt("Cancel")}
                  </Button>
                  <Button disabled={isPending} type="submit">
                    {isPending ? (
                      <Loader className="animate-spin h-5 w-5" />
                    ) : (
                      <>
                        {t("CreateCustomer")}
                        <Check className="ml-2" />
                      </>
                    )}
                  </Button>
                </div>
              </form>
            </Form>
          </FormProvider>
        }
        rightContent={<SupportCard />}
      />
    </Shell>
  );
};
