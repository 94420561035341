import { useToast } from "@/hooks/utils/useToast.ts";
import { api } from "@/services/api";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface EditKeyDatesBody {
  inform_user: boolean;
  key_dates: {
    possession_date: Date | undefined;
  };
}

interface EditKeyDatesParams {
  user_property_id: number;
  possession_date: Date | null;
  inform_user?: boolean;
}

export const useEditKeyDates = () => {
  const { toast } = useToast();
  const { t } = useTranslation("hooks");
  return useMutation({
    mutationFn: async ({
      user_property_id,
      possession_date,
      inform_user = false,
    }: EditKeyDatesParams) => {
      const { data } = await api.post(
        `v1/user_properties/${user_property_id}/edit_key_dates`,
        <EditKeyDatesBody>{
          inform_user,
          key_dates: {
            possession_date,
          },
        }
      );
      return data;
    },
    onSuccess() {
      toast({
        title: t("Success.Update.Default"),
        variant: "success",
        duration: 3000,
      });
    },
    onError(error) {
      toast({
        title: t("Error.Update.Default"),
        description: error.message,
        variant: "destructive",
      });
    },
    retry: false,
  });
};
